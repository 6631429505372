:root {

    --highlight-color: #2da575;
    --white-color: #fff;
    --black-color: #000;
    --bg-color: #021c46;

}


/* Global styles */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: var(--bg-color);
    color: var(--white-color);
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: var(--white-color);
}

ul {
    list-style: none;
}

/* Container */

/* .container {
    width: 90%;
    margin: 0 auto;
} */

/* Buttons */

.btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: var(--highlight-color);
    color: var(--white-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s;
}