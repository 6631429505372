/* our services */

.ourservices_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.ourservices_title {
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 50px;
    text-align: center;
    line-height: 150%;
}

.card_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Display 4 cards per row */
    gap: 50px;
    justify-items: center;
    /* margin-top: 50px; */
}

.card_container .card {
    width: 290px;
    height: 310px;
    /* Default height for larger screens */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: 2px solid #2da575;
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    background: linear-gradient(321deg,
            rgba(5, 22, 47, 0.52) 50.04%,
            #2167d3 114.97%);
    box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.card_container .card:hover {
    box-shadow: 0px 0px 10px 0px #2da575;
    transition: 0.2s;
}

/* Responsive breakpoints */

@media (max-width: 1280px) {
    .card_container {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media (max-width: 768px) {
    .card_container {
        grid-template-columns: 1fr;
    }
    .ourservices_content{
        margin-top: 50px;
    }
    .ourservices_content .ourservices_title{
        margin-bottom: -20px;
    }

    .card_container .card {
        height: auto;
        gap: 20px;
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .card_container .card {
        padding: 15px;
        font-size: 14px;
        height: auto;
    }
}