.footer {
  background-color: #11122e;
  height: 350px;
  color: #fff;
  
}

.footer_contaier {
  width: 80%;
  height: 90%;
  margin: 0 auto;
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.footer_logo {
  width: 60px;
  height: 60px;
}

.address_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* height: 100%; */
  /* width: 25%; */
}

.footer_navigation {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* width: 25%; */
}

.footer_nav {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.footer_nav p {
  padding-bottom: 20px;
}

.footer_nav p:hover {
  /* color: #f5c518; */
  color: #2da575;
  cursor: pointer;
}
.footer_contact p {
  padding: 0px 0px 20px 0px;
  text-decoration: underline;
  letter-spacing: 1px;
}
.footer_right_reserverd p {
  font-weight: 200;
  font-size: 12px;
}


@media (max-width: 500px) {
  .footer_contaier {
    width: 100%;
    padding: 30px 0px;
  }
  .address_container {
    width: 100%;
}
}
@media (max-width: 750px) {
  /* .footer_contaier{
    width: 100%;
  } */
  .footer {
    height: auto;
  }
  .footer_contaier {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    padding: 0px 20px;
  }
  .footer_contact p {
    text-align: center;
    display: flex;
    margin-top: 8px;
    padding: 2px 0px;
    text-decoration: none;
  }
  .footer_navigation {
    width: 100%;
  }
 
  .footer_nav {
    justify-content: flex-start;
    gap: 50px;
  }
  
}
@media (max-width: 950px) {
  .footer_contaier {
    width: 100%;
  }
 .footer .img {
    display: none;
  }
  .footer_contaier {
    justify-content: space-around;
  }
  
}

@media (max-width: 1134px) {
  .footer_contaier {
    width: 80%;
    padding: 30px 0px;
  }
}