@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .span_left {
    color: rgb(8, 214, 91);
    margin-right: 10px;
  }
  .glass_effect {
    background: rgb(103 100 182 / 19%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .glass_effect_inner {
    background: rgba(103, 100, 182, 0.007);
    box-shadow: inset 0 4px 30px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  @layer components {
    .btn-hover-effect {
      @apply bg-[--highlight-color] text-white px-6 py-2 rounded-md transition duration-300 ease-in-out transform hover:bg-green-600 hover:drop-shadow-lg hover:scale-105;
    }
  }
  .btn-hover-effect {
    background-color: var(--highlight-color);
    color: white;
    padding: 0.5rem 1.5rem;
    box-shadow: 0 10px 5px rgba(0, 0, 0, 0.25); /* Creates a drop shadow */
    border-radius: 0.375rem; /* Roughly equivalent to Tailwind's rounded-md */
    transition: transform 0.1s ease-in-out, background-color 0.3s ease-in-out,
      box-shadow 0.3s ease-in-out;
  }

  .btn-hover-effect:hover {
    
    background-color: #1c9f5e; /* Darker green shade, adjust as needed */
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.25); /* Creates a drop shadow */
    transform: scale(1.01); /* Scales the button slightly on hover */
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding-top: 64px; /* Adjust based on your navbar height */
}
nav a.active {
  color: blue; /* Active link color */
  font-weight: bold;
}

/* Add this CSS to your styles */
.navbar {
  transition: transform 0.3s ease-in-out;
}

.navbar-hidden {
  transform: translateY(-100%); /* Move the navbar out of view */
}
