.popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    z-index: 1000;
}

.popup_content {
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    text-align: center;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

}

.popup_content img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.close_button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--highlight-color);
    color: white;
    border: none;
    /* padding: 10px; */
    width: 30px;
    height: 30px;
    border-radius: 50px;
    cursor: pointer;
}