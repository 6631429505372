.maintenance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    color: white;
    gap: 10px;
}


.bussinessImage {
    width: auto;
    height: 500px;
    object-fit: cover;
    object-position: center;
}

.maintenance h1 {
    font-size: 1.3rem;
    font-weight: 800;
    margin-bottom: 2.5rem;
    color: var(--highlight-color-light);
    animation: fadeIn 2s infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 1;
        text-shadow: 0px 0px 8px rgba(255, 0, 0, 0.7);
        /* Softer shadow */
    }

    50% {
        opacity: 0.5;
        text-shadow: 0px 0px 15px rgba(255, 0, 0, 0.4);
        /* Soft glow */
    }

    100% {
        opacity: 1;
        text-shadow: 0px 0px 8px rgba(255, 0, 0, 0.7);
    }
}


@media (max-width: 768px) {

    h1 {
        font-size: 2rem;
    }
}