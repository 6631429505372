.container_hero {
  position: relative;
  /* height: 100vh; */
  width: 100%;
  overflow: hidden;
  max-width: 1200px;
  padding: 0px 20px;
  margin: 0 auto;
}
.image1 {
  position: absolute;
  background-repeat: no-repeat;
  top: -400px;
  left: -350px;
  transform: translate(50px, 20px);
  /* height: 500px; */
  width: 700px;
  z-index: -1; /* Behind other content */
}
.image2 {
  position: absolute;
  background-repeat: no-repeat;
  top: -200px;
  right: -550px;
  transform: translate(-50px, 555px);
  width: 1000px;
  z-index: -1; /* Behind other content */
}
.image3 {
  position: absolute;
  background-repeat: no-repeat;
  top: 25%;
  left: -600px;
  transform: translate(-50px, 555px);
  width: 1500px;
  z-index: -1; /* Behind other content */
}
.image4 {
  position: absolute;
  background-repeat: no-repeat;
  top: 60%;
  right: -750px;
  transform: translate(-50px, 555px);
  width: 1500px;
  z-index: -1; /* Behind other content */
}

.hero {
  height: 82vh;
  color: #fff;
}

@media (max-width:750px){
.hero {
  height: 100%;
  color: #fff;
}
}

.hero_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.hero_content .hero_title {
  /* font-size: 38px; */
  /* font-weight: 700;
  margin-bottom: 20px;
  text-align: left; */
  line-height: 150%;
}

.highlight {
  color: #2bb77f;
}

.hero_content .hero_text {
  /* font-size: 18px;
  font-weight: 300; */
  line-height: 30px;
  /* width: 60%; */
  /* margin-bottom: 20px;
  font-family: "Roboto", sans-serif; */
  letter-spacing: -0.01em;
}

.btn_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.btn {
  /* width: 140.88px;
  height: 45.61px; */
  /* margin: 10px 30px 0px 0px; */
  background-color: #2da575;
  border-radius: 50px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  text-align: center;
}

.btn:hover {
  background-color: #fff;
  color: #2da575;
  transition: 0.5s;
}

.hero_img img {
  width: 100%;
  height: auto;
  width: auto\9;
}

.hero_img {
  max-width: 50%;
}

/* about us */

.aboutus_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  color: #fff;
}

.aboutus_content .aboutus_title {
  font-size: 38px;
  font-weight: 700;
  /* margin-top: 100px; */
  text-align: center;
  line-height: 150%;
}

.aboutus_content .aboutus_text {
  font-size: 18px;
  /* text-align: justify; */
  max-width: 1200px;
  margin: auto;
}

.aboutus_content .aboutus_text p {
  margin-bottom: 10px;
  line-height: 1.75rem;
}

.aboutus_img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.aboutus_img p {
  width: 400px;
}

.aboutus_text .highlight {
  font-size: larger;
}

.aboutus_cotent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  margin-top: 20px;
}

.spacer {
  height: 10vh;
}

/* customer_review */
.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  gap: 150px;
  flex-direction: row;
}

.customer_review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  color: #fff;
}

.Customers_Reviews_title {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: left;
  line-height: 150%;
}

.customer_review_card {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 20px;
  height: 303px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  color: black;
}

.relative_box {
  position: relative;
  width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-family: "Poppins";
}

.avatar {
  position: absolute;
}

.customer_review_card .client_name {
  color: #2da575;
  font-size: 14px;
  line-height: 23px;
  font-weight: 700;
  letter-spacing: 2px;
}

.reviews {
  font-weight: bolder;
  /* padding: 20px; */
  text-align: left;
  font-size: 14px;
  line-height: 23px;
}

.stars {
  text-align: left;
  padding-bottom: 8px;
}

.card2 {
  margin-top: -150px;
}

.customer_review_card:hover {
  box-shadow: 0px 0px 10px 10px #2da575;
  transition: 0.4s;
}

.checked {
  color: orange;
}

.technologies_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  color: #fff;
}

.technologies_content img {
  width: 100%;
}

a {
  text-decoration: none;
}

@media (max-width: 900px) {
  .hero_img img {
    display: none;
  }

  .cards {
    flex-wrap: wrap;
    gap: 40px;
    margin: 20px 0px 50px 0px;
  }

  .technologies_content {
    display: none;
  }

  .card2 {
    margin-top: 0px;
  }

  .aboutus_content .aboutus_text {
    width: 100%;
    margin-top: 0%;
    line-height: 130%;
    font-size: large;
  }

  .spacer {
    height: 0vh;
  }

  .hero_content .hero_title {
    font-size: 28px;
  }

  .hero_content .hero_text {
    font-size: 16px;
    width: 100%;
    font-weight: 400;
  }
}

@media (max-width: 1134px) {
  .cards {
    gap: 90px;
  }
}

@media (max-width: 1400px) {
  .technologies_container img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 971px) {
  .technologies_content {
    height: auto;
    margin-top: 20px;
  }

  .technologies_container img {
    width: 800px;
    height: auto;
  }

  .cards {
    gap: 20px;
  }

  .aboutus_cotent {
    flex-direction: column-reverse;
    margin-top: 0px;
  }

  .aboutus_content .aboutus_text {
    width: auto;
  }
}
